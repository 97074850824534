<template>
  <CostPerLiterStat />
</template>

<script>
import CostPerLiterStat from '../../../components/stat/CostPerLiterStat'

export default {
  name: 'PerLiter',
  components: {
    CostPerLiterStat,
  }
}
</script>